import React from "react"
import { graphql } from "gatsby"
import { useTheme } from "emotion-theming"
import { injectIntl } from "gatsby-plugin-intl"

import PageContainer from "../components/page-container"
import SEO from "../components/seo"
import { Col } from "../components/base"
// import DragContainer from '../components/dragContainer/dragContainer';
import ScrollMagic from "../components/dragContainer/scrollMagic"

import { TailwindConfig } from "../config/theme/tailwindType"

import { Section3, Section4, Section5 } from "../content/home/section"
import useWindowSize, { getSize } from "../hooks/useWindowSize"

import { MenuWrapper } from "../components/container/menuWrapper"
import { BG_TYPES } from "../components/svg/Backgrounds"

export const query = graphql`
  query HomePageQuery($locale: String) {
    allStrapiHome(filter: { locale: { eq: $locale } }) {
      nodes {
        id
        position
        text
        title
        link
      }
    }
    allStrapiHomeInteractiveContent(filter: { locale: { eq: $locale } }) {
      nodes {
        icon
        title
        text
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1048) {
                sizes
                src
                srcSet
              }
            }
          }
        }
      }
    }
  }
`

const HomePage = ({ data, intl }) => {
  const { type } = useWindowSize()
  const layout = getSize(type, ["1fr", "1fr 4fr 1fr", "1fr 1fr 2fr 1fr 1fr"])
  const alignVertical = getSize(type, ["center", "flex-start", "center"])
  const marginVertical = getSize(type, [0, 150, 0])
  const interactiveSections = data.allStrapiHomeInteractiveContent.nodes ?? {}
  const homeData =
    data.allStrapiHome?.nodes?.sort((a, b) => a.position - b.position) ?? {}

  const theme: TailwindConfig = useTheme()
  const {
    colors: { gray },
  } = theme

  const section3Component = (
    <Section3
      key={"section3"}
      title={homeData?.[0]?.title ?? ""}
      paragraph={homeData?.[0]?.text ?? ""}
      layout={layout}
      alignVertical={alignVertical}
      marginVertical={marginVertical}
    />
  )
  const section4Component = (
    <Section4
      key={"section4"}
      mainTitle={homeData?.[1]?.title ?? ""}
      mainText={homeData?.[1]?.text ?? ""}
      textLink={homeData?.[1]?.link ?? ""}
      sections={interactiveSections}
      layout={layout}
      alignVertical={alignVertical}
      marginVertical={marginVertical}
    />
  )
  const section5Component = (
    <Section5
      key={"section5"}
      title={homeData?.[2]?.title ?? ""}
      text={homeData?.[2]?.text ?? ""}
      textLink={homeData?.[2]?.link ?? ""}
      layout={layout}
      alignVertical={alignVertical}
      marginVertical={marginVertical}
    />
  )

  const sections = [section3Component, section4Component, section5Component]

  return (
    <PageContainer>
      <SEO title="Home" />
      <Col>
        <MenuWrapper bgType={BG_TYPES.NONE} footer={false}>
          {/* {isBrowser() ? <DragContainer>{sections}</DragContainer> : <></> } */}
          <ScrollMagic>{sections}</ScrollMagic>
        </MenuWrapper>
      </Col>
    </PageContainer>
  )
}

export default injectIntl(HomePage)
