import React from 'react';
import styled from "@emotion/styled"

type AnimationLayerProps = {
    children: any,
    zIndex?: number,
    r?:number
    positionX?:number,
    positionY?:number,
    opacity?: number,
    onClick?:any
}

export const AnimationLayer = ({ children, zIndex, positionX, positionY, r, opacity, onClick }: AnimationLayerProps) =>  <div {...onClick && {onClick}}>
    <AnimationCircle onClick={onClick}  positionX={positionX} positionY={positionY} r={r} zIndex={zIndex} opacity={opacity}>
        {children}
    </AnimationCircle>
    <AnimationContainer opacity={opacity} >
        <AnimationCircleBlend positionX={positionX} positionY={positionY} r={r}/>
    </AnimationContainer>
</div>


export const AnimationContainer = styled.div<{zIndex?:number, opacity?: number}>`
    position: fixed;
    z-index: ${({zIndex=5}) => zIndex};
    width: 100%;
    height: 100%;
    mix-blend-mode: difference;
    opacity: ${({opacity = 1}) => opacity};

`
export const AnimationCircle = styled.div<{r?:number, positionX?:number, positionY?:number, zIndex?:number, opacity?: number}>`
    position: absolute;
    top: ${({positionY=50}) => positionY}%;
    left: ${({positionX=66}) => positionX}%;
    margin-top: -${({r=200}) => r/2}px;
    margin-left: -${({r=200}) => r/2}px;
    width: ${({r=200}) => r}px;
    height: ${({r=200}) => r}px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${({opacity = 1}) => opacity};
    z-index: ${({zIndex=8}) => zIndex};
`

export const AnimationCircleBlend = styled(AnimationCircle)<{r?:number, positionX?:number, positionY?:number}>`
    position: absolute;
    top: ${({positionY=50}) => positionY}%;
    left: ${({positionX=66}) => positionX}%;
    margin-top: -${({r=200}) => r/2}px;
    margin-left: -${({r=200}) => r/2}px;
    width: ${({r=200}) => r}px;
    height: ${({r=200}) => r}px;
    background-color: white;
    z-index: 5
`