import React from 'react';
import styled from "@emotion/styled"
import tw from "tailwind.macro";

import { MenuWrapper } from './menuWrapper';
import { BG_TYPES } from '../svg/Backgrounds';

type SectionContainerProps = {
    children: any,

}
export const SectionContainer = ({ children }: SectionContainerProps) => <Container>
        {children}
</Container>


const Container = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: visible;
    ${tw`
        bg-primary22
    `}
`

