import React from "react"

import { SectionContainer } from "../../../components/container/sectionContainer"
import { AnimationLayer } from "../animationLayer/animationLayer"
import { Title } from "../../../components/content/title"
import { AnimationContent3 } from "../animationLayer/animationContent"
import ScrollDown from "../../../components/scrollDown/scrollDown"
import BackgroundCircle from "../../../components/svg/BackgroundCircle"
import {
  FullScreenContent,
  MobileFullScreenContent,
  ContentLeft,
} from "../../../components/container/fullScreenContent"
import { SimpleParagraph } from "../../../components/content/paragraph"
import useWindowSize, {
  getSize,
  isMobile,
  isTablet,
} from "../../../hooks/useWindowSize"
import { Text } from "../../../components/base/typo"
import { noop } from "lodash"
import { CustomReactMarkdown } from "../../../components/customReactMarkdown"

export const Section3 = ({
  title,
  paragraph,
  layout,
  alignVertical = "center",
  marginVertical = 0,
  progress = 1,
  animOnClick = noop,
}) => {
  const { type } = useWindowSize()
  const positionX = getSize(type, [75, 50, 66])
  const positionY = getSize(type, [80, 70, 50])
  const r = getSize(type, [150, 250, 250])
  const animLayerOpacity =
    progress === 1 ? 1 : Math.max(Math.min(0, 1 - progress), 0)
  // console.log(`section 3 animLayerOpacity ${animLayerOpacity}`)

  return (
    <div>
      <SectionContainer>
        <BackgroundCircle />
        {!isMobile(type) && (
          <AnimationLayer
            onClick={animOnClick}
            positionX={positionX}
            positionY={positionY}
            r={r}
          >
            <AnimationContent3 r={r / 2} />
          </AnimationLayer>
        )}
        {!isMobile(type) ? (
          <FullScreenContent layout={layout} alignVertical={alignVertical}>
            <ContentLeft
              className={isTablet(type) ? "px-20" : ""}
              marginVertical={isTablet(type) ? 200 : marginVertical}
            >
              <Title color="white" bold>
                <CustomReactMarkdown source={title} />
              </Title>
              <SimpleParagraph color="white">
                <Text size="lg" weight="light" color="white">
                  {paragraph}
                </Text>
              </SimpleParagraph>
            </ContentLeft>
          </FullScreenContent>
        ) : (
          <>
            <div className="mt-10" />
            <MobileFullScreenContent>
              <Title color="white" bold>
                <CustomReactMarkdown source={title} />
              </Title>
              <SimpleParagraph color="white">{paragraph}</SimpleParagraph>
            </MobileFullScreenContent>
          </>
        )}
      </SectionContainer>
      {!isMobile(type) && <ScrollDown />}
    </div>
  )
}
