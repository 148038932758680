import React from 'react';
import { themeGet } from '@styled-system/theme-get'
import styled from "@emotion/styled"
import tw from "tailwind.macro";
import useWindowSize, { isMobile, isTablet, isDesktop } from '../../../hooks/useWindowSize';
import { Text } from '../../../components/base/typo'

const CIRCLE_CONTENT_SIZE = 360;

const getTransformX = (angle:number, radius:number) => {
    const radians = (angle*Math.PI) / 180;
    const x = Math.cos(radians)*radius;
    return x
}

const getTransformY = (angle:number, radius:number) => {
    const radians = (angle*Math.PI) / 180;
    const y = Math.sin(radians)*radius;
    return y
}

const getColor = (active:boolean) => active ? 'mixColor' : 'white';

type AnimationInteractiveProps = {
    index: string,
    onSelect: Function,
    onDeselect: Function,
    circles: Array<any>,
    steps: Array<any>,
    content: any,
    r: number
}

export const AnimationInteractive = ({index, onSelect, onDeselect, circles, steps, content, r}:AnimationInteractiveProps) => {
    const Content = content
    const {type} = useWindowSize()
    return (
        <>
            <AnimationContentStyle6>
            <CircleContent size={isDesktop(type) ? 360 : 200}>
                <Content />
            </CircleContent>
            </AnimationContentStyle6>
            {!isMobile(type) && <AnimationContentStyle6>
                {circles.map((circle, i) => {
                    const Icon = index === circle.index ? circle.icon.active : circle.icon.default;
                    return (
                        <Circle key={i} size={circle.size} angle={circle.angle} radius={circle.radiusFraq ? (circle.radiusFraq*r)/2 : r*7/24} active={index===circle.index}
                        onMouseEnter={() => onSelect(circle.index)}
                        onMouseLeave={() => onDeselect('')}
                        onClick={() => onSelect(circle.index)}>
                            <Icon />
                        </Circle>
                    )})}
                {steps.map((step,i) => (
                    <Step key={i} angle={step.angle} radius={step.radiusFraq ? (step.radiusFraq*r)/2 : r*7/24}>
                        <Text color='white' size='2xl'>{step.step}</Text>
                    </Step>
                ))}
            </AnimationContentStyle6> }
        </>
    )
}

const Step = styled.div<{angle:number, radius:number}>`
    ${tw`
    absolute
    flex
    justify-center
    items-center
    `}
    transform: ${({angle=50, radius=100}) => `translate(${getTransformX(angle, radius)}px, ${getTransformY(angle, radius)}px)`};
`

const Circle = styled.div<{size:number, angle:number, active:boolean, radius:number}>`
    ${({size=10}) => `
        height: ${size}px;
        width: ${size}px;
    `}
    position: absolute;
    transform: ${({angle=50, radius=100}) => `translate(${getTransformX(angle, radius)}px, ${getTransformY(angle, radius)}px)`};
    ${tw`
        flex
        justify-center
        items-center
    `}
`
const CircleContent = styled.div<{size:any}>`
    width: ${({size}) => `${size}px`};
    height: ${({size}) => `${size}px`};
    border-radius: 50%;
    z-index: 15;
    ${tw`
        flex
        justify-center
        items-center
    `}
`

const AnimationContentStyle6 = styled.div`
    position: absolute;
    ${tw`
        flex
        justify-center
        items-center
    `}
`