import React from "react"
import ReactMarkdown from "react-markdown"

import { SectionContainer } from "../../../components/container/sectionContainer"
import { BackgroundLayer } from "../../../components/backgroundLayer/backgroundLayer"
import { AnimationLayer } from "../animationLayer/animationLayer"
import { ContentLayer } from "../../../components/contentLayer/contentLayer"
import { Title } from "../../../components/content/title"
import {
  SectionParagraph,
  SimpleParagraph,
} from "../../../components/content/paragraph"
import { AnimationContent4 } from "../animationLayer/animationContent"
import ScrollDown from "../../../components/scrollDown/scrollDown"
import BreadCrum from "../../home/breadcrum/breadCrum"
import { BackgroundCircle } from "../../../components/svg"
import {
  FullScreenContent,
  MobileFullScreenContent,
  ContentLeft,
} from "../../../components/container/fullScreenContent"
import useWindowSize, {
  getSize,
  isMobile,
  isTablet,
} from "../../../hooks/useWindowSize"
import ROUTES from "../../../config/constants/route"
import { SecondaryButton } from "../../../components/buttons/buttons"
import { Text } from "../../../components/base/typo"
import { interpolate } from "../../../helpers/utils"
import { CustomReactMarkdown } from "../../../components/customReactMarkdown"

export const Section5 = ({
  title,
  text,
  textLink,
  layout,
  alignVertical = "center",
  marginVertical = 0,
  progress = 1,
}) => {
  const { type } = useWindowSize()

  const initPositionY = getSize(type, [80, 70, 50])
  const finalPositionY = getSize(type, [80, 66, 50])
  const positionY = interpolate({
    start: initPositionY,
    end: finalPositionY,
    progress,
  })

  const initPositionX = getSize(type, [80, 50, 66])
  const finalPositionX = getSize(type, [50, 50, 70])
  const positionX = interpolate({
    start: initPositionX,
    end: finalPositionX,
    progress,
  })

  const initR = getSize(type, [400, 500, 700])
  const finalR = getSize(type, [600, 1200, 2000])
  const r = interpolate({ start: initR, end: finalR, progress })

  return (
    <div>
      <SectionContainer>
        <BackgroundCircle />
        {!isMobile(type) && (
          <AnimationLayer
            positionX={positionX}
            positionY={positionY}
            r={r}
            zIndex={6}
          >
            <AnimationContent4 />
          </AnimationLayer>
        )}
        {!isMobile(type) ? (
          <FullScreenContent layout={layout} alignVertical={alignVertical}>
            <ContentLeft
              className={isTablet(type) ? "px-20" : ""}
              marginVertical={marginVertical}
            >
              <Title color="white" bold>
                {title}
              </Title>
              <SimpleParagraph color="white">
                <Text size="lg" weight="light" color="white">
                  {text}
                </Text>
              </SimpleParagraph>
              <div className="my-10" />
              <SecondaryButton link={ROUTES.MUSES} text={textLink} />
            </ContentLeft>
          </FullScreenContent>
        ) : (
          <>
            <MobileFullScreenContent>
              <Title color="white" bold>
                <CustomReactMarkdown className="pt-20" source={title} />
              </Title>
              <SimpleParagraph color="white">{text}</SimpleParagraph>
              <div className="py-8" />
              <SecondaryButton link={ROUTES.MUSES} text={textLink} />
            </MobileFullScreenContent>
          </>
        )}
      </SectionContainer>
    </div>
  )
}
