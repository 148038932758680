import React, { useState } from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "react-intl"

import { SectionContainer } from "../../../components/container/sectionContainer"
import { AnimationLayer } from "../animationLayer/animationLayer"
import { Title } from "../../../components/content/title"
import { SimpleParagraph } from "../../../components/content/paragraph"
import { AnimationContent6 } from "../animationLayer/animationContent"
import ScrollDown from "../../../components/scrollDown/scrollDown"
import { BackgroundCircle } from "../../../components/svg"

import {
  SvgBrainIconCircle,
  SvgBrainIconCircleActive,
  SvgCameraIconCircle,
  SvgCameraIconCircleActive,
  SvgCirclesIconCircle,
  SvgCirclesIconCircleActive,
  SvgHeartIconCircle,
  SvgHeartIconCircleActive,
  SvgHistogramIconCircle,
  SvgHistogramIconCircleActive,
  SvgMicIconCircle,
  SvgMicIconCircleActive,
  SvgPenIconCircle,
  SvgPenIconCircleActive,
  SvgPlusIconCircle,
  SvgPlusIconCircleActive,
} from "../../../assets/svg"

import { SvgAnimationLayerVoice } from "../../../assets/svg"
import {
  FullScreenContent,
  MobileFullScreenContent,
  ContentLeft,
} from "../../../components/container/fullScreenContent"
import { SecondaryButton } from "../../../components/buttons/buttons"
import useWindowSize, {
  getSize,
  isMobile,
  isTablet,
} from "../../../hooks/useWindowSize"
import ROUTES from "../../../config/constants/route"
import { Text } from "../../../components/base/typo"
import { noop } from "lodash"
import { interpolate } from "../../../helpers/utils"
import { formatImageData } from "../../../helpers/formatGraphqlData"
import { CustomReactMarkdown } from "../../../components/customReactMarkdown"

const circles = [
  {
    index: "heart",
    size: 85,
    angle: -140,
    radiusFraq: 0.8,
    icon: {
      default: SvgHeartIconCircle,
      active: SvgHeartIconCircleActive,
    },
  },
  {
    index: "photo",
    size: 41,
    angle: -90,
    radiusFraq: 0.8,
    icon: {
      default: SvgCameraIconCircle,
      active: SvgCameraIconCircleActive,
    },
  },
  {
    index: "write",
    size: 41,
    angle: -102,
    radiusFraq: 0.85,
    icon: {
      default: SvgPenIconCircle,
      active: SvgPenIconCircleActive,
    },
  },
  {
    index: "speak",
    size: 41,
    angle: -160,
    radiusFraq: 0.85,
    icon: {
      default: SvgMicIconCircle,
      active: SvgMicIconCircleActive,
    },
  },
  {
    index: "cloud",
    size: 80,
    angle: 45,
    radiusFraq: 0.9,
    icon: {
      default: SvgBrainIconCircle,
      active: SvgBrainIconCircleActive,
    },
  },
  {
    index: "visualize",
    size: 41,
    angle: -10,
    radiusFraq: 0.8,
    icon: {
      default: SvgHistogramIconCircle,
      active: SvgHistogramIconCircleActive,
    },
  },
  {
    index: "statistic",
    size: 80,
    angle: -45,
    radiusFraq: 0.9,
    icon: {
      default: SvgCirclesIconCircle,
      active: SvgCirclesIconCircleActive,
    },
  },
  {
    index: "create",
    size: 80,
    angle: 130,
    radiusFraq: 0.8,
    icon: {
      default: SvgPlusIconCircle,
      active: SvgPlusIconCircleActive,
    },
  },
]

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const ImageContentStyle = styled.img<{ title: any }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  animation: ${fadeIn} 1s ease-in-out;
`

// when progress = 1 --> normal size

export const Section4 = ({
  sections,
  mainTitle,
  mainText,
  textLink,
  layout,
  alignVertical = "center",
  marginVertical = 0,
  progress = 1,
  animOnClick = noop,
}) => {
  const { locale } = useIntl()
  const data = useStaticQuery(graphql`
    query HomeInteractiveTextQuery {
      allStrapiHomeInteractiveText {
        nodes {
          text
          position
          locale
        }
      }
    }
  `)

  const interactiveTextData =
    data.allStrapiHomeInteractiveText.nodes
      ?.filter(node => node.locale === locale)
      ?.sort((a, b) => a?.position - b?.position)
      ?.map(node => node.text) ?? {}
  const steps = [
    {
      step: `01. ${interactiveTextData?.[0] ?? ""}`,
      angle: 150,
      radiusFraq: 1.0,
    },
    {
      step: `02. ${interactiveTextData?.[1] ?? ""}`,
      angle: -120,
      radiusFraq: 1.0,
    },
    {
      step: `03. ${interactiveTextData?.[2] ?? ""}`,
      angle: -23,
      radiusFraq: 1.1,
    },
    {
      step: `04. ${interactiveTextData?.[3] ?? ""}`,
      angle: 60,
      radiusFraq: 1.0,
    },
  ]

  const { type } = useWindowSize()
  const [index, setIndex] = useState("")
  const positionX = getSize(type, [80, 50, 66])
  const positionY = getSize(type, [80, 70, 50])

  const initR = getSize(type, [150, 250, 250]) * 1
  const finalR = getSize(type, [400, 500, 700])
  const r = interpolate({ start: initR, end: finalR, progress })

  const { image, title, text } =
    sections.filter(section => section.icon === index)[0] || []
  const ImageContent = () =>
    image ? (
      <ImageContentStyle title={title} src={formatImageData(image)} />
    ) : null
  const content = image ? ImageContent : SvgAnimationLayerVoice
  const onSelectIndex = newIndex => {
    setIndex(newIndex === index ? -1 : newIndex)
  }
  return (
    <div>
      <SectionContainer>
        <BackgroundCircle />
        {!isMobile(type) && (
          <AnimationLayer
            onClick={animOnClick}
            positionX={positionX}
            positionY={positionY}
            r={r}
          >
            <AnimationContent6
              index={index}
              onSelect={onSelectIndex}
              onDeselect={setIndex}
              circles={r < 0.7 * finalR ? [] : circles}
              steps={r < 0.7 * finalR ? [] : steps}
              content={content}
              r={r}
            />
          </AnimationLayer>
        )}
        {!isMobile(type) ? (
          <FullScreenContent layout={layout} alignVertical={alignVertical}>
            <ContentLeft
              className={isTablet(type) ? "px-20" : ""}
              marginVertical={isTablet(type) ? 100 : marginVertical}
            >
              <Title color="white" bold>
                {title || mainTitle}
              </Title>
              <SimpleParagraph color="white">
                <Text size="lg" weight="light" color="white">
                  {text || mainText}
                </Text>
              </SimpleParagraph>
              <div className="my-10" />
              <SecondaryButton link={ROUTES.SERVICES} text={textLink} />
            </ContentLeft>
          </FullScreenContent>
        ) : (
          <>
            <div className="mt-10" />
            <MobileFullScreenContent>
              <Title color="white" bold>
                <CustomReactMarkdown source={title || mainTitle} />
              </Title>
              <SimpleParagraph color="white">
                {text || mainText}
              </SimpleParagraph>
              <div className="py-8" />
              <SecondaryButton link={ROUTES.SERVICES} text={textLink} />
            </MobileFullScreenContent>
          </>
        )}
      </SectionContainer>
      {!isMobile(type) && <ScrollDown />}
    </div>
  )
}
