import styled from "@emotion/styled"
import React from 'react'

import { AnimationInteractive } from './animationInteractive';

import { SvgAnimationLayerVoice, SvgAnimationLayerVoices } from '../../../assets/svg';

export const AnimationContent3 = ({r}) => <AnimationContent3Svg r={r}/>

const AnimationContent3Svg = styled(SvgAnimationLayerVoice)<{r:number}>`
    width: ${({r}) => r}px;
    height: ${({r}) => r}px;
`

export const AnimationContent4 = styled(SvgAnimationLayerVoices)`
    width: 100%;
    height: 40%;
    stroke-width: 6;
`

export const AnimationContent6 = AnimationInteractive;





