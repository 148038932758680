import React from 'react';
import styled from "@emotion/styled"
import tw from "tailwind.macro";

const scrollText = <>scroll</>

const ScrollDown = () => <Container>
    {scrollText}
</Container>
export default ScrollDown;

const Container = styled.div`
    position: absolute;
    bottom: 0;
    height: auto;
    ${tw`
        pl-8
        pb-8
        text-xs
        text-white
    `}
`