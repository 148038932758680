
import React from 'react';
import styled from "@emotion/styled";
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline, PlayState } from 'react-gsap';
import useWindowSize from '../../hooks/useWindowSize';
import { animateScroll } from 'react-scroll'
import { noop } from 'lodash';

const ScrollMagicCont = ({ children }) => {

    const childrenLength = children?.length ?? 0;
    const unitScroll = 1 / (children.length)
    const maxscroll = 1 - unitScroll;
    const maxScrollPercent = maxscroll / 100;
    const STEP = useWindowSize().height;

    const steps = new Array(childrenLength + 1).fill(1).map((_, i) => (i + 1) * (100 / (childrenLength + 2))).map(s => s * maxScrollPercent) // [20,40,60,80]
    // const steps = [20, 40, 60, 80].map(s => s * maxScrollPercent) // [5, 7, 17, 20]
    const tweenMax = 0.1;

    return <Container className="trigger">
        <Controller>
            <Scene
                triggerElement=".trigger"
                triggerHook={0}
                reverse={true}
                duration={'300%'}
            >
                {(scrollProgress) => {

                    return <>{
                        children.map((sec, ind) => {
                            let currentIndex = 2
                            if (scrollProgress < steps[0]) {
                                currentIndex = 0
                            }
                            else if (scrollProgress > steps[0] && scrollProgress < steps[2]) {
                                currentIndex = 1
                            }
                            let progress = 1; // 1 : hide by default, 0 : show
                            let circleProgress = 0;
                            if (ind === 1) {
                                circleProgress = Math.min(1, (scrollProgress - steps[0]) / (((steps[2] + steps[1]) / 2) - steps[0]));
                            } else if (ind === 2) {
                                circleProgress = Math.min(1, (scrollProgress - steps[2]) / (((steps[3] + steps[3]) / 2) - steps[2]));
                            }

                            const transitionTerm = 0.01;

                            if (scrollProgress < steps[0] && ind === 0) {
                                progress = 0
                            }
                            // fadeout 1, fadein 2
                            else if (scrollProgress > (steps[0] - transitionTerm) && scrollProgress < steps[1]) {
                                const progressUp = (scrollProgress - (steps[0] - transitionTerm)) / (steps[1] - (steps[0] - transitionTerm));
                                switch (ind) {
                                    case 0:
                                        progress = progressUp
                                        break;
                                    case 1:
                                        progress = (1 - progressUp)
                                        break;
                                }
                            }
                            else if (scrollProgress > (steps[1] - transitionTerm) && scrollProgress < steps[2] && ind === 1) {
                                // set 2
                                progress = 0;
                            }
                            // fadeout 2 fadein 3
                            else if (scrollProgress > (steps[2] - transitionTerm) && scrollProgress < steps[3]) {
                                const progressUp = (scrollProgress - (steps[2] - transitionTerm)) / (steps[3] - (steps[2] - transitionTerm));
                                switch (ind) {
                                    case 1:
                                        progress = progressUp
                                        break;
                                    case 2:
                                        progress = (1 - progressUp)
                                        break;
                                }
                            }
                            else if (scrollProgress > steps[3] && ind === 2) {
                                // set 3
                                progress = 0;
                            }


                            const animOnClick = () => {
                                if (ind === 0 || (ind === 1 && progress !== 0)) {
                                    const scrollToInit = 49 * STEP / 100
                                    const scrollToEnd = 100 * STEP / 100
                                    if( progress === 0) animateScroll.scrollTo(scrollToInit, { duration: 1 });
                                    setTimeout(() => {
                                        animateScroll.scrollTo(scrollToEnd, { duration: 1500 });
                                    }, 10)
                                }
                                else if (ind === 1 && progress === 0) {
                                    const scrollToEnd = 200 * STEP / 100
                                    animateScroll.scrollTo(scrollToEnd, { duration: 2500 });
                                }
                            }

                            return <Timeline
                                key={ind}
                            >
                                <Tween
                                    playState={PlayState.pause}
                                    progress={progress * tweenMax}
                                    from={{ autoAlpha: 1 }}
                                    to={{ autoAlpha: 0 }}
                                    ease="easeOutQuart"
                                >
                                    <SectionTop
                                        style={{ display: currentIndex === ind ? 'block' : 'none' }}
                                    >
                                        {React.cloneElement(sec, { progress: circleProgress, animOnClick })}
                                        {/* {currentIndex === ind ? React.cloneElement(sec, { progress: circleProgress, animOnClick }) : React.Fragment} */}
                                    </SectionTop>
                                </Tween>
                            </Timeline>
                        })}</>
                }}
            </Scene>
        </Controller>

    </Container>

}

export default ScrollMagicCont

const Container = styled.div`
height: ${300}vh;
right: -17px;
width: 100vw;
-ms-overflow-style: none;
scrollbar-width: none;
&::--webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
}
`

const SectionTop = styled.div`
z-index: 6;
position: fixed;
top: 0;
justify-content: center;
display: flex;
align-items: center;
height: 100vh;
width: 100%;
overflow: hidden;
`
